// Add to links #partners so it would scroll automatically on /aanbod page

module.exports = function addPartnersHashtagToLinks() {

    (function($) {

        if ($('body').hasClass('page-aanbod')) {
            const links = $('.toon-aanbod');
            links.each(function(){
                const newLink = $(this).attr("href") + "#partners";
                $(this).attr("href", newLink);
            })
        }

    })(jQuery);

}
