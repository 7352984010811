
// Update quantity value of input on extra products when clickicking "+" or "-" on checkout step 2

module.exports = function drupalBehaviors_checkoutExtraProducts() {
  Drupal.behaviors.extraProducts = {
    attach: function(context, settings) {
      (function($) {
        $('.views-row', context).once(function () {
            var wrap = $('[id^=wine-field-wrapper-], [id^=amount-field-wrapper-]', this);

            var input = $('input[type=text]', wrap);
            var amount = parseInt(input.val());

            wrap.find('a:first-child').click(function(e) {
                e.preventDefault();
                amount = amount < 2 ? amount : amount - 1;
                input.val( amount );
            });

            wrap.find('a:last-child').click(function(e) {
                e.preventDefault();
                amount += 1;
                input.val( amount );
            });
        })
      })(jQuery);
    }
  };
}
