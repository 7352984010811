
/*
* Lift up the contanct form on contact page up on XL resolutions to match it with header
*/

module.exports = function liftUpContactFormOnXl() {
  (function($) {

    $(window).on('load resize', function(){
      var offset = 0;
      var winWidth = window.innerWidth || document.body.clientWidth;

      if ($('.contact-page') && $('.contact-page').length) {
        if (winWidth >= 1277) {
          offset = $('.contact-page #page-title')[0]
            .getBoundingClientRect().height;
          offset = -(offset + 10);
        }
        $('.contact-page #webform-client-form-4')
            .css('marginTop', offset);
      }
    });

  })(jQuery);
}
