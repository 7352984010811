
// Add show more button for christmas wrappers if content is bigger than needed
// dynamycally add show more btn, add event listener and remove listener and itself when clicked

module.exports = function manageElemsChristmasPage() {
  (function($) {

    var showMoreElems = document.querySelectorAll('.node-christmas-package .group-cp-footer-group .field-name-field-cp-contents');
    [].forEach.call(showMoreElems, manageElems);

    function manageElems(el, ind, ar) {
      var inEl = el.querySelector('.field-items'),
          inHeight = el.getBoundingClientRect().height,
          outHeight = inEl.getBoundingClientRect().height;

      if (outHeight > inHeight) {
        var btn = document.createElement('BUTTON');
        var t = document.createTextNode('Toon de gehele inhoud');
        btn.setAttribute('class', 'btnMore_kerstpaket');
        btn.appendChild(t);
        el.parentElement.appendChild(btn);

        btn.addEventListener('click', function showMoreFn(ev) {
          ev.preventDefault();
          el.style.maxHeight = outHeight + 'px';
          this.removeEventListener('click', showMoreFn);
          this.parentElement.removeChild(this);
        });
      }
    }

  })(jQuery);
}
