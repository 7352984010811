
//Scrollig of sidebar on bestellen page on LG+

module.exports = function bestellenSidebarScroll() {
  (function($) {

    var bestellenForm = $('#giftcard-extra-form');
    if ( !bestellenForm.length ) { return; }

    var sidebar = $('#edit-cart-sidebar');

    if ( $('body').hasClass('mq-lg') || $('body').hasClass('mq-xl') ) {

        if ($(window).scrollTop() > bestellenForm.offset().top && $(window).scrollTop() < (bestellenForm.offset().top + bestellenForm.outerHeight() - sidebar.outerHeight())) {
            sidebar.css('top', $(window).scrollTop() - bestellenForm.offset().top + 20);
        }

        if ($(window).scrollTop() < bestellenForm.offset().top) {
            sidebar.css('top', '');
        }
    } else {
        sidebar.css('top', '');
    }

  })(jQuery);
}
