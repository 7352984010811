/*-----------------------------------------------------------------*//**
* create dropdown menu on small screens
*/

module.exports = function mobileNavigation() {
  (function($) {

    var clonedLogo = $('#navbar .header-container .logo').clone();
    clonedLogo.insertBefore( $('#main-menu > .nav-toggle-wrapper') );

    $('#main-menu').find('.region-navigation-xs').after('<div class="menu-overlay" id="menu-overlay"></div>');

    $('.nav-toggle').click(function(e) {
        //var lOff = $(this).offset().left;
        var loff = $("#main-menu .logo").offset().left;
        e.preventDefault();
        $(this).addClass('active');
        //$('.region-navigation-xs').animate({'left': -lOff+10}, 400);
        if ( $(window).width() > 1276 ) {
            $('.region-navigation-xs').animate({'left': -loff}, 400);
        } else {
            $('.region-navigation-xs').animate({'left': -1}, 400);
        }
        $('#menu-overlay').fadeIn(400);
        $('body').addClass('__menuOpened');
    });

    $('#menu-overlay').click(function() {
        $(this).fadeOut(400);
        $('.region-navigation-xs').animate({'left': '-100%'}, 400);
        $('body').removeClass('__menuOpened');
    });

  })(jQuery);
}
