/*-----------------------------------------------------------------*//**
* Toggle wine info on checkout step2
*/

module.exports = function toggleWineInfo() {
  (function($) {

    $('.showMore').on('click', function(ev) {
      ev.preventDefault();
      $(this)
        .closest('.node-wine-teaser-2')
        .find('.group-wine-content')
        .toggleClass('__opened');
    });

  })(jQuery);
}
