/*-----------------------------------------------------------------*//**
* Fix #navbar on top of the page on scroll event
*/

module.exports = function fixNavBarOnScroll() {
  (function($) {

    if ($(window).scrollTop() > 178) {
        $('body').addClass('fixedHeader');
    } else {
        $('body').removeClass('fixedHeader');
    }

  })(jQuery);
}
