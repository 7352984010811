
// Fix sidebar on checkout step 2 on LG+ resolution

module.exports = function fixedSidebarCheckout() {
  (function($) {

    if (!Modernizr.mq('(min-width: ' + '1024px)') || !($('body').hasClass('page-giftcards'))) {
        return;
    }
    $(".nxte-checkout-pane-cart-sidebar").stick_in_parent({
        parent: ".page-giftcards .block-system > .content",
        offset_top: 100
    });

  })(jQuery);
}
