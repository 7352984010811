var setDefaultRowHeights = require("./modules/defaultRowHeights");
var visitorInfo = require("./modules/visitorInfo");
var stickPlaceholders = require("./modules/stickPlaceholders");
var bodyExtraClass = require("./modules/bodyExtraClass");
var remarkTextareaAddFocusClass = require("./modules/remarkTextareaAddFocusClass");
var zakelijkPlaceholderDeliveryDate = require("./modules/zakelijkPlaceholderDeliveryDate");
var modernizrMediaQueries = require("./modules/modernizrMediaQueries");
var bestellenSidebarScroll = require("./modules/bestellenSidebarScroll");
var goToTopButton = require("./modules/goToTopButton");
var fixNavBarOnScroll = require("./modules/fixNavBarOnScroll");
var responsiveHandleResize = require("./modules/responsiveHandleResize");
var restRestaurantPageSidebarHeight = require("./modules/restRestaurantPageSidebarHeight");
var uniqCardShowMore = require("./modules/uniqCardShowMore");
var toggleWineInfo = require("./modules/toggleWineInfo");
var manageElemsChristmasPage = require("./modules/manageElemsChristmasPage");
var liftUpContactFormOnXl = require("./modules/liftUpContactFormOnXl");
var preventDefaulSubmitingOfFormKeuzeCadeaubonStepOne = require("./modules/preventDefaulSubmitingOfFormKeuzeCadeaubonStepOne");
var fixedSidebarCheckout = require("./modules/fixedSidebarCheckout");
var mobileNavigation = require("./modules/mobileNavigation");
var scrollOnHashLinks = require("./modules/scrollOnHashLinks");
var addPartnersHashtagToLinks = require("./modules/addPartnersHashtagToLinks");

var drupalBehaviors_formPlaceholders = require("./modules/drupalBehaviors/drupalBehaviors_formPlaceholders");
var drupalBehaviors_lastChilds = require("./modules/drupalBehaviors/drupalBehaviors_lastChilds");
var drupalBehaviors_responsiveEmbedWraper = require("./modules/drupalBehaviors/drupalBehaviors_responsiveEmbedWraper");
var drupalBehaviors_responsiveRowHeights = require("./modules/drupalBehaviors/drupalBehaviors_responsiveRowHeights");
var drupalBehaviors_responsiveIcheck = require("./modules/drupalBehaviors/drupalBehaviors_responsiveIcheck");
var drupalBehaviors_closePopUpOnBgcClick = require("./modules/drupalBehaviors/drupalBehaviors_closePopUpOnBgcClick");
var drupalBehaviors_checkoutExtraProducts = require("./modules/drupalBehaviors/drupalBehaviors_checkoutExtraProducts");
var drupalBehaviors_buttonHover = require("./modules/drupalBehaviors/drupalBehaviors_buttonHover");

(function($) {

  $(document).ready(function() {

    // Add site visitor info like his operating system and browser to the body class;
    visitorInfo();

    // Set row heights after all images load;
    $('body').imagesLoaded(function($images, $proper, $broken) {
        setDefaultRowHeights();
    });

    //Toggle wine info on checkout step2
    toggleWineInfo();

    // Add show more button for christmas wrappers if content is bigger than needed
    // dynamycally add show more btn, add event listener and remove listener and itself when clicked
    manageElemsChristmasPage();

    //Lift up the contanct form on contact page up on XL resolutions to match it with header
    liftUpContactFormOnXl();

    // Prevent Default submiting of the Form On Keuze Cadeaubone Step One Page
    preventDefaulSubmitingOfFormKeuzeCadeaubonStepOne();

    // Fix sidebar on checkout step 2 on LG+ resolution
    fixedSidebarCheckout();

    //create dropdown menu on small screens
    mobileNavigation();

    // Add focus class on giftcard step 2 remark textarea when somebody focuses in
    remarkTextareaAddFocusClass();

    // Changes orders of blocks with delivary date on Zakelik page
    zakelijkPlaceholderDeliveryDate();

    //set timeout for handling browser resize
    var handleResizeAfterResizeTimeOut;

    $(window).resize(function() {
        clearTimeout(handleResizeAfterResizeTimeOut);
        handleResizeAfterResizeTimeOut = setTimeout(function () {
            responsiveHandleResize();
            restRestaurantPageSidebarHeight();
            bestellenSidebarScroll();
        }, 100);
    }).trigger('resize');

    // Add scroll event to sidebar if page is checkout step 2 and has a sidebar
    $(window).scroll(function() {
        bestellenSidebarScroll();
    });

    // modernizer queries
    modernizrMediaQueries();

    // Add extra class to body depending on the type of the page
    bodyExtraClass();

    // Scroll to top when clicked on hash links
    scrollOnHashLinks();

    // Go to top button
    goToTopButton();

    // Show more cards
    uniqCardShowMore()

    // Fix #navbar on top of the page on scroll event (on all pages except checkout)
    $(window).on('scroll', fixNavBarOnScroll);
    // using passive:true flag for better performance
    // (means that event will not be prevented)
    window.addEventListener('scroll', fixNavBarOnScroll, {passive: true});

    // Add to links #partners so it would scroll automatically on /aanbod page
    addPartnersHashtagToLinks();

  })

  // Style form placeholders
  stickPlaceholders();

  // Style form placeholders
  drupalBehaviors_formPlaceholders();

  // Adds a class of last child to last child element like p or blockquote
  drupalBehaviors_lastChilds();

  // Adds a wraper around objects like: iframe embed object, currently didn't find a place where it's used
  drupalBehaviors_responsiveEmbedWraper();

  // Sets a default .row heights
  drupalBehaviors_responsiveRowHeights();

  // Style for input type checkbox
  drupalBehaviors_responsiveIcheck();

  // Close pop-up's by clicking on overlay
  drupalBehaviors_closePopUpOnBgcClick();

  // Update quantity value of input on extra products when clickicking "+" or "-" on checkout step 2
  drupalBehaviors_checkoutExtraProducts();

  // Change text on button hover
  drupalBehaviors_buttonHover();

})(jQuery);

