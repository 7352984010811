/*-----------------------------------------------------------------*//**
* Go to top button
*
* @author          Viktor Starynskyi
* @project         CACO1607 - CB
* @added           14.10.2017
* @contributors    Viktor Starynskyi
*/

module.exports = function goToTopButton() {
  (function($) {
    var btn = $('#goToTop');

    btn.on('click', function(e){
        e.preventDefault();

        $('html, body').stop().animate({scrollTop: 0}, 300);
    });

  })(jQuery);
}
