/*-----------------------------------------------------------------*//**
* Show more cards
*/

module.exports = function uniqCardShowMore() {
  (function($) {
    if ( !$('.view-mode-kkc').length ) { return; }

    var viewBlock = $('#block-views-card-types-block-3');
    var viewContent = viewBlock.find('.view-content');
    var viewFooter = viewBlock.find('.view-footer');

    var button = viewFooter.find('a');
    var buttonText = button.text();

    button.click(function(e) {
        e.preventDefault();

        var currentLabel = $(this).text();

        $(this).data('more', buttonText);
        $(this).data('less', 'Minder cadeaukaarten');

        viewContent.toggleClass('show-all-cards');

        $(this).text( currentLabel == $(this).data('more') ? $(this).data('less') : $(this).data('more') );
        $(this).toggleClass('show-less');
    });
  })(jQuery);
}
